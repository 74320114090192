// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-landing-jsx": () => import("./../../../src/pages/landing.jsx" /* webpackChunkName: "component---src-pages-landing-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-privacy-web-jsx": () => import("./../../../src/pages/privacy-web.jsx" /* webpackChunkName: "component---src-pages-privacy-web-jsx" */),
  "component---src-pages-quiz-jsx": () => import("./../../../src/pages/quiz.jsx" /* webpackChunkName: "component---src-pages-quiz-jsx" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

